import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

export default function Hero() {
  return (
    <Grid container sx={{ pt: "550px", '@media (min-width: 780px)' : {pt: '160px'}}}>
      <Grid item xs={12} lg={8}>
        <Typography variant="h1" sx={{ mb: 6, fontSize: '42px', lineHeight:"48px", ml:2, mr:2, '@media (min-width: 780px)' : {fontSize: "72px", lineHeight: 1.1,ml:0, mr:0}}}>
          Marketplace for Tokenized real estate
        </Typography>
        <Grid sx={{ display:"none", '@media (min-width: 780px)' : {display:"block"}}}>
          <Button href="https://app.citadao.io/" variant="contained" size="large">
            Launch App
          </Button>
        </Grid>
        <Grid sx={{ display:"block", '@media (min-width: 780px)' : {display:"none"}}}>
          <Button href="https://discord.com/invite/JjmDe2QdbJ" variant="contained" sx={{ width: "100%"}}>
            Join our Discord
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
