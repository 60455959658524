import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import VentureGridItem from "./VentureGridItem";
import Container from '@mui/material/Container'

export default function Venture() {
  return (
    <Box sx={{ bgcolor:"#FAFAFA", pt:"125px", pb:"200px"}}>
      <Container>
        <Typography variant="h2" sx={{ mb: 12}}>
          We’re Backed By
        </Typography>
        <Typography variant="h5" sx={{ mb: 6 }}>
          Venture Capital
        </Typography>

        <Grid
          container
          spacing={5}
          justifyContent="center"
          alignItems="center"
          flexBasis="25%"
          sx={{ mb: 12 }}
        >
          <VentureGridItem
            href="https://sora.vc/#portfolio"
            image="images/sora.png"
            name="Sora Ventures"
          />
          <VentureGridItem href="https://www.avaxavatar.com/" image="images/avatar.png" name="Avax Avatar" />
          <VentureGridItem href="https://snzholding.com/portfolio.html" image="images/snz.png" name="SNZ" />
          <VentureGridItem
            href="https://theorigincapital.com/"
            image="images/origin.png"
            name="Origin Captial"
          />
          <VentureGridItem
            href="https://bixinvc.com/#Portfolio"
            image="images/bixin.png"
            name="Bixin Ventures"
          />
          <VentureGridItem
            href="https://catchervc.chaincatcher.com/"
            image="images/catcher.png"
            name="Catcher VC"
          />
          <VentureGridItem
            href="https://www.turncapital.io/"
            image="images/turn.png"
            name="Turn Ventures"
          />
          <VentureGridItem
            href="http://www.supercandy.asia"
            image="images/Supercandy.png"
            name="Supercandy"
          />
        </Grid>

        <Typography variant="h5" sx={{ mb: 6 }}>
          Professionals &amp; Leaders From
        </Typography>
        <Grid
          container
          spacing={5}
          justifyContent="center"
          alignItems="center"
          flexBasis="25%"
        >
          <VentureGridItem
            href="https://synthetix.io/"
            image="images/synthetix.png"
            name="Synthetix"
          />
          <VentureGridItem href="https://www.canadaici.com/" image="images/canada.png" name="Canada ICI" />
          <VentureGridItem
            href="https://www.mercurial.finance/about"
            image="images/mer.png"
            name="Mercural Finance"
          />
          <VentureGridItem href="/" image="images/kyber.png" name="Kyber" />
          <VentureGridItem
            href="http://bitgo.com"
            image="images/bitgo.png"
            name="BitGo"
          />
          <VentureGridItem href="https://www.cbre.com/" image="images/cbre.png" name="CBRE" />
          <VentureGridItem
            href="https://www.parmenion.co.uk/"
            image="images/parmenion.png"
            name="Parmenion"
          />
        </Grid>
      </Container>
    </Box>
  );
}
