import { Button, Container, Grid, Box } from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";
import MobileNavBar from "./MobileNav";

function NavBar() {
  return (
    <Box
      sx={{
        top: 0,
        left: 0,
        position: "fixed",
        height: 100,
        width:'100%',
        backgroundColor: "white",
        zIndex: 50,
      }}
    >
      <Container>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className="gradientBorder"
          sx={{
            paddingLeft: 2,
            paddingRight: 2,
            height: 100,
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
          }}
        >
          <Grid item>
            <a href="/">
              <img src="images/citadao-logo.svg" height="40" alt="logo" />
            </a>
          </Grid>
          <Grid
            sx={{
              display: "none",
              "@media (min-width: 780px)": { display: "block" },
            }}
          >
            <Grid container alignItems="flex-end" spacing={6}>
              <Grid item>
                <a href="https://docs.citadao.io/intro/overview/intro">
                  <img src="images/gitbook.svg" alt="logo" />
                </a>
              </Grid>
              <Grid item>
                <a href="https://twitter.com/citadao_io">
                  <img src="images/twitter.svg" alt="logo" />
                </a>
              </Grid>
              <Grid item>
                <a href="https://discord.gg/JjmDe2QdbJ">
                  <img src="images/discord.svg" alt="logo" />
                </a>
              </Grid>
              <Grid item>
                <a href="https://citadao.medium.com/">
                  <img src="images/medium.svg" alt="logo" />
                </a>
              </Grid>
              <Grid item>
                <Button
                  href="https://app.citadao.io/"
                  variant="gradientOutlined"
                >
                  <span class="gradient-text">Launch App</span>
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            sx={{
              display: "block",
              "@media (min-width: 780px)": { display: "none" },
            }}
          >
            <MobileNavBar />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default NavBar;
