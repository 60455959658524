import styled from '@emotion/styled'
import { Container } from "@mui/material";

function Footer() {
    return (
      <Container>
        <FooterBar>
          <Section className="section-left">
            <img src="/images/citadaologo1.svg" alt="citadaoLogo"></img>
          </Section>
          <Section>
            <div className="links">
              <a href="https://docsend.com/view/wzheu2v3dbgunr7a">Litepaper</a>
              <a href="https://docs.citadao.io/intro/overview/intro">Docs</a>
              <a href="https://citadao.medium.com/">Our Blog</a>
            </div>
          </Section>
          <Section>
            <div className='section-right'>
              <div className="social-links">
                <a href='https://twitter.com/citadao_io'><img src="images/twitter.png" alt="logo" /></a>
                <a href='https://discord.gg/JjmDe2QdbJ'><img src="images/discord.png" alt="logo" /></a>
                <a href='https://citadao.medium.com/'><img src="images/medium.png" alt="logo" /></a>
              </div>
              <div className='audit'>
                <p>Audited By</p>
                <img src="/images/abdk.svg" alt="abdk"></img>
              </div>
            </div>
          </Section>
        </FooterBar>
      </Container>
      
    );
  }
  
const FooterBar = styled.div`
  width: 100%;
  display:flex;
  @media (max-width: 768px) {
    flex-direction: column;
  }

  .section-left {
    @media (max-width: 768px) {
      border-right: none;
      border-bottom: 1px solid  #D7D7D7;
    }
      border-right: 1px solid  #D7D7D7;
  }

  .section-right {
    border-left: 1px solid  #D7D7D7;
    width: 100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    height:100% ;
    justify-content: space-between;

    @media (max-width: 768px) {
      border-left: none;
      border-top: 1px solid  #D7D7D7;
      height:150px;
      padding-top: 50px;
    }
  }

  .links {
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
    height:150px;
    

    a {
      font-size:20px;
      text-decoration: none;
      color: #002E89;
    }
  }

  .social-links { 
    height: 40px;
    width: 200px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .social-links img { 
    height: 30px;
  }

  .audit {
    width: 200px;

    p {
      font-size:12px;
      margin-bottom:10px ;
    }

    @media (max-width: 768px) {
      padding-top: 25px;
      padding-bottom: 50px;
    }
  }

`


const Section = styled.div`
  height:150px;
  width:100%;
  margin: 125px auto;
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    height:fit-content;
    padding: 50px 0;
    margin: 0 auto;
  }
`
export default Footer;
