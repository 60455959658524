import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import styled from '@emotion/styled'

export default function TeamGridItem(props) {
  let linkImg = "";
  if (props.url && props.url.match("twitter")) {
    linkImg = <img src="images/twitter.png" alt="linkedin" width={20} />;
  } else if (props.url && props.url.match("linkedin")) {
    linkImg = <img src="images/linkedin.png" alt="linkedin" />;
  }

  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={3}
      alignItems="center"
      sx={{ textAlign: "center", marginBottom:4 }}
    >
      <img
        src={props.img}
        alt={props.name}
        sx={{
          width: "200px",
          height: "200px",
        }}
      />
      <Typography variant="h4">{props.name}</Typography>
      <Typography variant="subtitle2" sx={{ my: 1 }}>
        {props.title}
      </Typography>
      <a href={props.url} target="_blank" rel="noreferrer">
        {linkImg}
      </a>
      <AlignLeft><p className="teamCaption">{props.bio}</p></AlignLeft>
    </Grid>
  );
}

const AlignLeft = styled.div`
  margin: 0px 10px;
  margin-top:5px;
  
  p {
    font-size:18px;
  }
  
`