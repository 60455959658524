import Grid from "@mui/material/Grid";

export default function VentureGridItem(props) {
  return (
    <Grid item xs={12} sm={6} md={3} sx={{ textAlign: "center" }}>
      <a href={props.href}>
        <img
          src={props.image}
          alt={props.name}
          style={{ width: "50%", maxWidth: "100%" }}
        />
      </a>
    </Grid>
  );
}
