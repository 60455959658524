import { Container, Grid, Stack, Typography, Button, Box, useMediaQuery } from '@mui/material';

import MediumArticle from './MediumArticle';

export default function Medium() {
  const md = useMediaQuery((theme) => theme.breakpoints.up('md'));

  return (
    <Container>
      <Stack spacing={4} sx={{ mb: 4, mt: 4 }}>
        <Typography variant="h2">Our Medium Posts</Typography>
        <Grid
          container
          spacing={md ? 6 : 2}
          sx={{
            '.MuiGrid-item:nth-child(odd)': { paddingLeft: 0 },
            '@media (max-width: 780px)': { '.MuiGrid-item': { paddingLeft: 0 } },
          }}
        >
          <MediumArticle
            imgSrc="images/medium-avax.jpg"
            title="Announcing our ecosystem supporter: Avalanche Asia Star Fund"
            text="We are excited to announce that Avalanche Asia Star Fund (Avatar) has joined our panel of ecosystem supporters. We are glad to have..."
            url="https://citadao.medium.com/announcing-our-ecosystem-supporter-avalanche-asia-star-fund-2eb1dbe7525e"
          />
          <MediumArticle
            imgSrc="images/medium-abdk.jpg"
            title="CitaDAO Smart Contract Audited"
            text="Security is our top priority and we are excited to announce that our contract has been fully audited by ABDK..."
            url="https://citadao.medium.com/citadao-smart-contract-audited-9e1998e5bbd7"
          />
          <MediumArticle
            imgSrc="images/medium-evolution.jpg"
            title="Citadao: The Next Evolution in Real Estate Investment"
            text="Citadao is a revolutionary tokenized real estate platform that is launching soon. We are using blockchain based Decentralized Finance..."
            url="https://citadao.medium.com/citadao-the-next-evolution-in-real-estate-investment-90330249dbb5"
          />
          <MediumArticle
            imgSrc="images/medium-chainlink.jpg"
            title="CitaDAO Is Integrating with Chainlink to bring Real Estate Appraisal Data and Tokenized..."
            text="We're excited to announce that CitaDAO is integrating industry-leading oracle solution Chainlink to unlock tokenized real estate use..."
            url="https://citadao.medium.com/citadao-is-integrating-with-chainlink-to-bring-real-estate-appraisal-data-and-tokenized-real-9589cb443b19"
          />
        </Grid>
        <Box>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} md={3}>
              <Button variant="outlined" sx={{ width: '100%' }} href={'https://citadao.medium.com/'}>
                Visit our Medium
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </Container>
  );
}
