import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FeatureCard from "./FeatureCard";

export default function Features(props) {
  return (
    <Box sx={{ ...props.sx }}>
      <Grid container spacing={5}>
        <Grid item xs={12} md={6} lg={4}>
          <FeatureCard
            img={<img src="images/feature1.png" alt="feature1" />}
            title="Productive yield farming via real world assets"
            content="Gain exposure to real world asset that has intrinsic yield
        generation capability and low price correlation with other
        cryptocurrency."
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <FeatureCard
            img={<img src="images/feature2.png" alt="feature3" />}
            title="Access DeFi Composable Real World Assets"
            content="Achieve greater real world asset value through unlocking on-chain use cases enabled by DeFi interoperability."
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <FeatureCard
            img={<img src="images/feature3.png" alt="feature3" />}
            title="24/7 Secondary market for Real Estate tokens "
            content="Swap Real Estate Tokens anytime without significant slippage on secondary market to enter or exit existing positions."
          />
        </Grid>
      </Grid>
    </Box>
  );
}
