import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TeamGridItem from "./TeamGridItem";
import { Stack, Typography, Button } from "@mui/material";
import { useState } from "react";
import { useElementSize } from "usehooks-ts";

export default function Team() {
  const [viewAll, setViewAll] = useState(false);

  const [remainingTeamRef, { height: remainingTeamHeight }] = useElementSize();
  const handleViewAllClick = () => {
    setViewAll(!viewAll);
  };

  return (
    <Stack spacing={4}>
      <Typography variant="h2" sx={{ mb: 6, fontSize: '60px', lineHeight:"72px", '@media (min-width: 780px)' : {fontSize: "72px", lineHeight: 1.1}}}>
        Core Contributors
      </Typography>
      <Grid container>
        <TeamGridItem
          name="Joel"
          img="images/joel2.png"
          title="Real Estate"
          bio="Real estate expert with more than 12 years experience in institutional real estate and structured $2 billion worth of real estate deals. He is determined to bridge together the real estate space and cryptocurrency ecosystem."
          url="https://www.linkedin.com/in/joelcitadao/"
        ></TeamGridItem>
        <TeamGridItem
          name="Johnson"
          img="images/johnson2.png"
          title="Engineering"
          bio="A curious engineer with 10 years of experience of developing, executing, maintaining web applications and software solutions. Solid reputation for delivering project accuracy and end user functionality. Determined to shape the future of tokenised real estate in the web3 world."
          url="https://www.linkedin.com/in/johnson-chin/"
        ></TeamGridItem>
        <TeamGridItem
          name="Ethan"
          img="images/ethan2.png"
          title="Growth &amp; Strategy"
          bio="A problem solver that brings  experience from big tech and startup into growing the CitaDAO community. Ethan was previously  with Amazon, City Storage Systems, and Uber managing  programs to drive growth of the various business. He is excited to be able to contribute to the web3 ecosystem."
          url="https://www.linkedin.com/in/ethanow/"
        ></TeamGridItem>
        <TeamGridItem
          name="Bella"
          img="images/bella2.png"
          title="Community Engagement"
          bio="A high-energy full-stack marketer with international and intercultural work experience in social media, brand marketing, and promotional events spanning over five years. Exceptional track record in innovative go-to-community strategies, building positive-sum relationships, driving awareness & building high gravity community from the ground up."
          url="https://www.linkedin.com/in/vothitraly/"
        ></TeamGridItem>
      </Grid>

      <Box
        sx={{
          height: viewAll ? remainingTeamHeight : 0,
          transitionProperty: "all",
          transitionDuration: "0.5s",
          transitionTimingFunction: "ease",
          overflow: "hidden",
        }}
      >
        <div ref={remainingTeamRef}>
          <Grid container>
          <TeamGridItem
              name="Kelly"
              img="images/kelly.png"
              title="Community Manager"
              bio="Expertise in community management, sales and marketing, and event organizing. Proven ability to build, maintain, and manage online communities with high levels of involvement through regular social media content, as well as updates on new trends and live events, all while keeping marketing strategy in mind. Obsessed with figuring out how to build a worldwide real estate DeFi community."
              url="https://www.linkedin.com/in/kelly-tran-321234166"
            ></TeamGridItem>
            <TeamGridItem
              name="Ngoc"
              img="images/ngoc.png"
              title="Community Manager"
              bio="A well-rounded professional with experience in both residential and commercial realms of real estate industry. Worked on various acclaimed projects in Singapore, Myanmar and Vietnam and honed the skills in real estate business including strategic thinking, insight research, project positioning and feasibility study which have been harnessed over a decade. Passionate about how real estate tokenization can transform the Tradfi and make it more accessible to anyone"
              url=""
            ></TeamGridItem>
            <TeamGridItem
              name="Tu"
              img="images/tu.png"
              title="Engineering"
              bio="A software engineer with 5 years of experience in designing and developing a wide variety of complex web based business applications. Now I'm getting to another exciting field in my career path - the Web3 world"
              url="https://www.linkedin.com/in/tu-nguyen-a52b58125"
            ></TeamGridItem>
            <TeamGridItem
              name="Nancy"
              img="images/nancy.png"
              title="UI/UX"
              bio="A &quot;full-stack designer&quot; who could work on both Graphic, Branding, Motion, Video as well as Product and UI/UX Design"
              url="https://twitter.com/nancy_ng_92/"
            ></TeamGridItem>
            <TeamGridItem
              name="Alvin"
              img="images/alvin.png"
              title="Growth &amp; Strategy"
              bio="Commercial real estate investment/ structure finance professional with over 10 years of experience in the field across multiple markets. Alvin has had experience structuring/ investing over USD$1.5b in debt/value over his career. Alvin makes it his purpose to make Commercial Real Estate accessible for everyone borderlessly and permissionlessly."
              url="https://www.linkedin.com/in/alvin-ngo/"
            ></TeamGridItem>
            <TeamGridItem
              name="Jensen"
              img="images/jensen.png"
              title="&nbsp;"
              bio="An experienced Information Technology professional and project manager. He has held technical and management positions in all areas of Information Technology industry including IT Operations, IT Outsourcing, Network Administration, Applications Development, Strategic Planning and all levels of IT Management."
              url="https://www.linkedin.com/in/jensen-l-a9898431/"
            ></TeamGridItem>
              <TeamGridItem
              name="Pei Koon Khoo"
              img="images/pei.png"
              title="Growth & Strategy"
              bio="Established problem solver with a couple of years of experience. A strategy analyst, Pei Koon has had experience planning out the multi-country strategy for an innovative fintech. Pei Koon joined CitaDAO to make commercial real estate more investible for everyone, and help everyone, especially the little guys, capture value from real estate appreciation."
              url="https://www.linkedin.com/in/peikoon?originalSubdomain=sg"
            ></TeamGridItem>

          </Grid>
        </div>
      </Box>
      <Box
        sx={{
          my: 4,
          opacity: viewAll ? 0 : 1,
          transitionProperty: "opacity",
          transitionDuration: "0.5s",
          transitionTimingFunction: "ease",
          overflow: "hidden",
        }}
      >
        <Grid container justifyContent="center" sx={{ pb: "150px" }}>
          <Grid item xs={3}>
            <Button
              sx={{ width: "100%" }}
              variant="outlined"
              onClick={handleViewAllClick}
            >
              View More
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
}
