import { Grid, Link, Typography } from "@mui/material";

export default function MediumArticle({ imgSrc, title, text, url }) {
  return (
    <Grid item xs={12} md={6}>
      <img
        src={imgSrc}
        width="100%"
        alt=""
        style={{
          borderRadius: 10,
        }}
      />
      <Typography variant="h4" sx={{mb:2}}><Link sx={{textDecoration:"none", color:"#02318F"}}href={url}>{title}</Link></Typography>
      <Typography variant="subtitle1" sx={{mb:1}}>{text}</Typography>
      <Typography variant="subtitle1"><Link sx={{fontWeight: "bold", textDecoration:"none"}}href={url}>Read More</Link></Typography>
    </Grid>
  );
}
