import { Box, Button, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import PropertyImageCarousel from "./PropertyImageCarousel";
import styled from "@emotion/styled";

export default function IRO(props) {
  return (
    <Box sx={{ ...props.sx }}>
      <Typography variant="h2" sx={{ mb: "70px" }}>
        Real Estate Token Launch
      </Typography>
      <PropertyImageCarousel sx={{ mb: 6 }} />

      <Typography variant="h3">Prime Industrial Unit in Singapore</Typography>
      <br />
      <Box sx={{ maxWidth: "950px", margin: "auto" }}>
        <p>
          The property is a strata industrial space in the vibrant Midview City
          in Singapore. The property is a 1,356 sq ft net industrial space on
          the 2nd floor industrial building.
        </p>
        <br />
        <p>
          The space is leased to a well capitalized non-profit organization with
          a gross rental yield of 4.1%. There is a high demand for the space due
          to its prime location and superb connectivity.
        </p>
        <br />
        <p>
          The unit is located within Midview City, an integrated business hub
          situated in the central part of Singapore. The building is sited in
          over 552,000 square feet of land area. It is an eight story building
          and an exclusive 3 story terrace block. a vibrant new high street.
        </p>
        <br />
      </Box>
      <Grid container sx={{ textAlign: "left", mb: "100px" }}>
        <Grid
          item
          container
          xs={12}
          sm={6}
          md={4}
          sx={{ height: "85px", mb: "30px", mt: "30px" }}
        >
          <Grid item xs={4}>
            <Img src="images/calendar.svg" alt="calendar" />
          </Grid>
          <Grid item xs={8} className="iro-text">
            <Typography variant="h4">Status</Typography>
            <Typography variant="subtitle2" sx={{fontSize:22}}>IRO Completed</Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={6}
          md={4}
          sx={{ height: "80px", mb: "30px", mt: "30px" }}
        >
          <Grid item xs={4}>
            <Img src="images/activity.svg" alt="activity" />
          </Grid>
          <Grid item xs={8} className="iro-text">
            <Typography variant="h4">Duration</Typography>
            <Typography variant="subtitle2" sx={{fontSize:24}}>5 days</Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={6}
          md={4}
          sx={{ height: "80px", mb: "30px", mt: "30px" }}
        >
          <Grid item xs={4}>
            <Img src="images/trending-up.svg" alt="trending up" />
          </Grid>
          <Grid item xs={8} className="iro-text">
            <Typography variant="h4">Current Rental</Typography>
            <Typography variant="subtitle2" sx={{fontSize:24}}>4.1% per annum</Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={6}
          md={4}
          sx={{ height: "80px", mb: "30px", mt: "30px" }}
        >
          <Grid item xs={4}>
            <Img src="images/pricetag.svg" alt="price tag" />
          </Grid>
          <Grid item xs={8} className="iro-text">
            <Typography variant="h4">Value</Typography>
            <Typography variant="subtitle2" sx={{fontSize:24}}>USD 630,000</Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={6}
          md={4}
          sx={{ height: "80px", mb: "30px", mt: "30px" }}
        >
          <Grid item xs={4}>
            <Img src="images/map.svg" alt="map" />
          </Grid>
          <Grid item xs={8} className="iro-text">
            <Typography variant="h4">Total Size</Typography>
            <Typography variant="subtitle2" sx={{fontSize:24}}>1356 sqft</Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={6}
          md={4}
          sx={{ height: "80px", mb: "30px", mt: "30px" }}
        >
          <Grid item xs={4}>
            <Img src="images/pin.svg" alt="pin" />
          </Grid>
          <Grid item xs={8} className="iro-text">
            <Typography variant="h4">Location</Typography>
            <Typography variant="subtitle2" sx={{fontSize:24}}>Singapore</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Box>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={3}>
            <Button variant="outlined" sx={{ width: "100%" }} href="https://docs.citadao.io/intro/iro-details/singapore-industrial-building-iro">
              More Details
            </Button>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              href="https://app.uniswap.org/#/swap?inputCurrency=0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48&outputCurrency=0x0e84296da31b6c475afc1a991db05e79633e67b0&chain=mainnet"
              variant="contained"
              sx={{ width: "100%" }}
            >
              Swap Now
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

const Img = styled.img`
  height: 65px;
  margin: 5px 0px 5px 0;
  padding-right: 20px;
  padding-left: 40px;
`;
