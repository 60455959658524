import { Typography } from "@mui/material";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";

export default function FeatureCard(props) {
  return (
    <Card
      sx={{
        px: 6,
        pt: 4,
        pb: 4,
        height: "100%",
      }}
    >
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        {props.img}
        <Typography variant="h4" className="minorGradient" sx={{ mb: 2}}>
          {props.title}
        </Typography>
        <Typography variant="subtitle1">{props.content}</Typography>
      </Box>
    </Card>
  );
}
