import { Box, Container, Typography } from '@mui/material';

export default function Model() {
  return (
    <Box
      sx={{
        minHeight: '1100px',
        backgroundImage: 'url(./images/model-visual.svg)',
        backgroundRepeat: 'no-repeat, repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        textAlign: 'center',
        my: 12,
      }}
    >
      <Container>
        <Box sx={{ py: 4 }}>
          <Typography variant="h2" sx={{ mb: 12 }} color="primary">
            How it works
          </Typography>
          <Box sx={{ display: 'none', '@media (min-width: 780px)': { display: 'block' } }}>
            <img src="images/howitwork.png" alt="model" style={{ width: 'auto', maxWidth: '100%' }} />
          </Box>
          <Box sx={{ display: 'block', '@media (min-width: 780px)': { display: 'none' } }}>
            <img src="images/howitwork-mobile.png" alt="model" style={{ width: 'auto', maxWidth: '100%' }} />
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
