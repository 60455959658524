import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { color } from "@mui/system";

const colors = {
  text: "#001847",
  gray: "#787878",
  brightBlue: "#04E9F8",
  blue: "#2574FB",
  darkBlue: "#0C46B9",
  darkerBlue: "#02318F",
  blueGradientStart: "#1056D7",
  blueGradientEnd: "#3B86FF",
  teamBlue: "#003acc",
  lightGray: "#DADADA",
  lighterGray: "#2574FB",
};

const minorGradient = `-webkit-linear-gradient(0deg, #002E89, #1056D7)`;
const blueGradient = `-webkit-linear-gradient(210deg, ${colors.blueGradientStart}, ${colors.blueGradientEnd})`;
const superGradient = `linear-gradient(65.76deg, ${colors.brightBlue} -32.71%, ${colors.blueGradientStart} 71.74%, ${colors.blueGradientEnd} 176.18%)`;
const borderGradient = "linear-gradient(135deg, #04e9f8, #3b86ff) 1";

const fontFamily = ['"Open Sans"', "sans-serif"];

export const brightDropShadow =
  "drop-shadow(0px 10px 60px rgba(134, 199, 255, 0.5))";

export let theme = createTheme({
  palette: {
    primary: {
      main: colors.blue,
    },
    secondary: {
      main: colors.darkBlue,
    },
    text: {
      primary: colors.text,
    },
  },

  typography: {
    fontFamily: fontFamily.join(", "),
    fontSize: 22,
    fontWeight: 300,
    h1: {
      fontWeight: 700,
      fontSize: "72px",
      lineHeight: 1.1,
      textTransform: "capitalize",
      background: superGradient,
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
    h2: {
      fontWeight: 700,
      fontSize: "60px",
      lineHeight: 1.1,
      textTransform: "capitalize",
      color: colors.blue,
      textAlign: "center",
    },
    h3: {
      fontWeight: 600,
      fontSize: "42px",
      lineHeight: 1.1,
      textAlign: "center",
      color: colors.blue,
    },
    h4: {
      fontWeight: 700,
      fontSize: "22px",
      lineHeight: 1.3,
      textTransform: "capitalize",
      color: colors.darkBlue,
    },
    h5: {
      fontWeight: 700,
      fontSize: "28px",
      lineHeight: 1.4,
      textAlign: "center",
      letterSpacing: "10px",
      textTransform: "uppercase",
      color: "#2574FB",
    },
    subtitle1: {
      fontWeight: 300,
      fontSize: "18px",
      lineHeight: 1.3,
    },
    subtitle2: {
      fontSize: "18px",
      color: "primary",
    },
    
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontWeight: 300,
        },
        ".minorGradient": {
          background: minorGradient,
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
        },
        ".gradientBorder": {
          borderImage: borderGradient,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          filter: brightDropShadow,
          borderRadius: "24px",
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "gradientOutlined" },
          style: {
            padding: "0.35em 3em",
            "& .gradient-text": {
              background: superGradient,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            },
            
            fontFamily: ["'Red Hat Display'", ...fontFamily].join(", "),
            fontSize: "14px",
            fontWeight: 700,
            textTransform: "none",
            border: `2px solid ${colors.blue}`,
            borderImage: borderGradient,
          },
        },
      ],
      styleOverrides: {
        contained: {
          padding: "6px 48px",
          borderRadius: "10px",
          background: superGradient,
          textTransform: "unset",
          
          fontWeight: 700,
          "&:hover": {
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            border: `2px solid ${colors.blue}`,
            fontWeight: 700,
            padding: "4px 48px",
          },
        },

        outlined: {
          fontWeight: "bold",
          background: "superGradient",
          borderRadius: "10px",
          borderWidth: "2px",
          borderColor: colors.teamBlue,
          textTransform: "unset",
          color: colors.teamBlue,
          "&:hover": {
            borderWidth: "2px",
            borderColor: colors.teamBlue,
          },
        },
      },
    },
  },
});

// theme = responsiveFontSizes(theme);
