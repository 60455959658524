import { Button, Container, Grid, Box, Typography, Link } from "@mui/material";
import { useState } from "react";
import styled from "@emotion/styled";

const MenuItem = (props: any) => {
  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="start"
      alignItems="center"
      sx={{
        width: 1,
        height: 80,
        borderBottom: 1,
        borderstyle: "solid",
        borderColor: "#DADADA",
      }}
    >
    <Box sx={{width:40}}><img src={props.image} alt="logo" /></Box>
    <Link href={props.link} target="_blank" rel="noreferrer" underline="none" variant='h3' sx={{color:'#001847', marginLeft:2}}><Typography>{props.children}</Typography></Link>
    </Grid>
  );
};

function MobileNavBar() {
  const [showMenu, setShowMenu] = useState(false);

  let menu = (<>
    <Box
          sx={{
            backgroundColor: "white",
            width: 1,
            position: "fixed",
            top: 100,
            left: 0,
            zIndex:30,
            height:450,
            opacity:'95%'

          }}
        ></Box>
    <Box
      sx={{
        width: 1,
        position: "fixed",
        top: 100,
        left: 0,
        zIndex:50
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{paddingLeft:3, paddingRight:3}}
      >
        <MenuItem icon={null} link='https://docs.citadao.io/intro/overview/intro' image="images/gitbook.svg">Documentation</MenuItem>
        <MenuItem icon={null} link='https://twitter.com/citadao_io' image="images/twitter.svg">Twitter</MenuItem>
        <MenuItem icon={null} link='https://discord.gg/JjmDe2QdbJ' image="images/discord.svg">Discord</MenuItem>
        <MenuItem icon={null} link='https://citadao.medium.com/' image="images/medium.svg">Blog</MenuItem>
        <Button sx={{marginTop:5, marginBottom:5, width:'100%',height:50, fontSize:'20px'}} href="https://discord.gg/JjmDe2QdbJ" variant="gradientOutlined"><span class="gradient-text">Join our Discord</span></Button>
      </Grid>
    </Box></>
  );

  return (
    <div>
      {showMenu ? <img src="images/closeTab.svg" height="48" alt="tab" onClick={() => setShowMenu((prevShowMenu) => !prevShowMenu)}/> :<img src="images/mobileTab.svg" height="48" alt="tab" onClick={() => setShowMenu((prevShowMenu) => !prevShowMenu)}/>}
      {showMenu ? menu : null}
    </div>
  );
}

export default MobileNavBar;

// const Menu = styled.div`
//   width: 300px,
//   height: 300px,
//   display:'fixed'
// `
