import { Stack, Button, TextField, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import styled from "@emotion/styled";
import Recaptcha from "react-recaptcha";
import Box from "@mui/material/Box";
import { useState } from "react";

export default function Mailing() {
  let [displaySuccess,setDisplaySuccess] = useState(false);
  const mailerApiUrl = "https://mailer.citadao.io";

  const clearWaitListErrorMessage = () => {
    document.querySelectorAll(".waitlist-error").forEach((message) => {
      message.innerHTML = "";
    });
  };

  const clearWaitListInputField = () => {
    document.querySelectorAll('input[type="email"]').forEach((input) => {
      input.value = "";
    });
  };

  const handleSubmitWaitListForm = (event) => {
    event.preventDefault();

    const currentForm = event.currentTarget;

    const emailField = currentForm.querySelector('input[type="email"]');
    const submitButton = currentForm.querySelector('button[type="submit"]');
    const errorMessageField =
      currentForm.parentElement.querySelector(".waitlist-error");

    clearWaitListErrorMessage();
    addSubmittingStatus(emailField, submitButton);

    fetch(`${mailerApiUrl}/subscribe`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      referrerPolicy: "no-referrer",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: emailField.value, group: "eng" }),
    })
      .then((res) => res.json())
      .then((data) => {
        removeSubmittingStatus(emailField, submitButton);

        if (data.code !== 200) {
          errorMessageField.innerHTML = data.message;
          return;
        }

        if (data.code === 200) {
          clearWaitListInputField();
          errorMessageField.innerHTML = "";
          setDisplaySuccess(true)
        }
      })
      .catch((err) => {
        removeSubmittingStatus(emailField, submitButton);
        errorMessageField.innerHTML = "Something went wrong";
      });
  };

  const addSubmittingStatus = (emailField, submitButton) => {
    submitButton.setAttribute("disabled", true);
    emailField.setAttribute("disabled", true);
    submitButton.classList.toggle("loading");
    emailField.classList.toggle("loading");
    submitButton.innerHTML = `
      Submitting
    `;
  };

  const removeSubmittingStatus = (emailField, submitButton) => {
    submitButton.removeAttribute("disabled");
    emailField.removeAttribute("disabled");
    submitButton.classList.toggle("loading");
    emailField.classList.toggle("loading");
    submitButton.innerHTML = "Submit";
  };

  function onReCaptChaCallbackBottom(token) {
    const submitButton = document.querySelectorAll(
      'main .citadao-waitlist-form button[type="submit"]'
    )[0];

    if (!submitButton) {
      return;
    }
    console.log("Success");
    submitButton.removeAttribute("disabled");
  }

  return (
    <Box
      sx={{
        background: "linear-gradient(90deg, rgba(0,0,0,0) 0%, #E5F6FF 100%)",
        pt: "125px",
        pb: "150px",
      }}
    >
      <Container>
        {displaySuccess ? (
          <Stack sx={{ textAlign: "center" }} spacing={4} alignItems="center">
            <Typography variant="h2">THANK YOU</Typography>

            <Typography variant="p" sx={{ fontSize: "20px", maxWidth: "sm" }}>
              Updates on upcoming real estate is on its way to your inbox. <br />
              <br /> If you can't find our emails, check your
              <Box fontWeight="fontWeightBold" display="inline">
                {" "}
                Spam
              </Box>{" "}
              or
              <Box fontWeight="fontWeightBold" display="inline">
                {" "}
                Promotion
              </Box>{" "}
              folder. Please mark the address as 'Not Spam'. We appreciate your
              help!
            </Typography>
          </Stack>
        ) : (
          <Stack sx={{ textAlign: "center" }} spacing={4} alignItems="center">
            <Typography variant="h2">Join Our Mailing List</Typography>
            <Typography variant="p" sx={{ fontSize: "24px" }}>
              Join our mailing list to get updates on our latest launch and
              industry trends on Real Estate Tokenization
            </Typography>

            <form
              className="form citadao-waitlist-form"
              onSubmit={handleSubmitWaitListForm}
            >
              <TextField
                size="small"
                label="Email"
                name="email"
                type="email"
                placeholder="Enter Email"
                required
              />
              <Button type="submit" variant="contained" sx={{ mx: 2 }}>
                Join Now
              </Button>
            </form>
            <p className="small-description waitlist-error"></p>
          </Stack>
        )}
      </Container>
    </Box>
  );
}
