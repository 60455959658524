import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Features from "./components/Features";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import IRO from "./components/IRO";
import Model from "./components/Model";
import Team from "./components/Team";
import Venture from "./components/Venture";
import Medium from "./components/Medium";
import Mailing from "./components/Mailing";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { theme } from "./theme";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <NavBar></NavBar>

      <Box
        sx={{
          backgroundImage: "url(./images/top-visual.svg)",
          backgroundRepeat: "no-repeat no-repeat",
          backgroundPosition: "top -250px right -500px",
          '@media (min-width: 780px)' : {
            backgroundPosition: "top -100px right -200px",
        }}}
      >
        <Container>
          <Stack>
            <Hero />
            <Features
              sx={{
                mt: "200px",
              }}
            />
            <IRO
              sx={{
                mt: "100px",
                textAlign: "center",
              }}
            />
          </Stack>
        </Container>

        <Model />

        <Container>
          <Stack spacing={10}>
            <Team />
          </Stack>
        </Container>

        <Venture />

        <Container>
          <Medium />
        </Container>
        <Mailing />
      </Box>
      <Footer></Footer>
    </ThemeProvider>
  );
}

export default App;
