import { useState, useEffect } from 'react';

import { useElementSize } from 'usehooks-ts';

import { Box } from '@mui/material';
import Carousel from 'react-material-ui-carousel';

const imageAspectRatio = 601 / 1200;

export default function PropertyImageCarousel(props) {
  var items = [
    {
      src: 'images/singaporeIRO.png',
      alt: 'iro',
    },
  ];
  const [boxRef, { width }] = useElementSize();
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setHeight(imageAspectRatio * width);
  }, [width]);

  return (
    <Box sx={{ ...props.sx }} ref={boxRef}>
      <Carousel height={height} IndicatorIcon={null}>
        {items.map((item, i) => (
          <CarouselItem key={i} {...item} />
        ))}
      </Carousel>
    </Box>
  );
}

function CarouselItem(props) {
  return (
    <Box>
      <img src={props.src} alt={props.alt} style={{ width: 'auto', maxWidth: '100%' }} />
    </Box>
  );
}
